export const FirebaseConfig = {
	"projectId": "loshay-rides-c8069",
	"appId": "1:159867595917:web:595a7c7f40b57a251b46c4",
	"databaseURL": "https://loshay-rides-c8069-default-rtdb.firebaseio.com",
	"storageBucket": "loshay-rides-c8069.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyC8chPFAt3aIdRYYys1I-zOcNeoHmg2Cuc",
	"authDomain": "loshay-rides-c8069.firebaseapp.com",
	"messagingSenderId": "159867595917",
	"measurementId": "G-VFDRW5HFSY"
};